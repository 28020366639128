import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import { z } from "zod";
import { mountPromotedAdDisplay } from "../../mounts/promoted_ad_display";

import { locationParams, replaceHistoryState, param } from "../../utils";
import { Control } from "../../control";
import { CodeSnippetListItemController } from "../code_snippet_list_item_controller";
import { mountApp } from "../../vue";
import { getUserVotes, mountComments } from "../../mounts/comments";
import { highlightEscapedCode } from "../../ui";
import { markdownWithLanguage } from "../markdown_display";

const DetailsProps = z.object({
  action: z.enum(["none", "code_update", "fixture_update", "full_update"]),
  tags: z.array(z.string()),
  language: z.string(),
  testLanguage: z.string(),
  user: z.object({ id: z.string(), username: z.string() }),
  description: z.nullable(z.string()).default(null),
  code: z.optional(z.string()).default(""),
  setupCode: z.optional(z.string()).default(""),
  package: z.optional(z.string()).default(""),
  fixture: z.optional(z.string()).default(""),
  exampleFixture: z.optional(z.string()).default(""),
  solutionGroupId: z.nullable(z.string()),
  validFixture: z.optional(z.nullable(z.boolean())).default(null),
  isTranslation: z.boolean(),
  // TODO Switching to another fork after expanding the output continues to show the original
  runResult: z.nullable(
    z.object({
      response: z.optional(
        z.object({
          result: z.object({}).passthrough(),
        })
      ),
    })
  ),
});

const TabsProps = z.object({
  canClose: z.boolean(),
  activeTab: z.enum(["details", "diff", "comments"]),
  hasDiff: z.boolean(),
  authorView: z.boolean(),
  commentsCount: z.number().int(),
  isPublished: z.boolean(),
  publishedForks: z.boolean(),
  editUrl: z.string(),
  newUrl: z.string(),
  closeUrl: z.optional(z.string()),
});

const DiffsProps = z.object({
  descriptionDiff: z.optional(z.string()).default(""),
  codeDiff: z.optional(z.string()).default(""),
  setupCodeDiff: z.optional(z.string()).default(""),
  packageDiff: z.optional(z.string()).default(""),
  fixtureDiff: z.optional(z.string()).default(""),
  exampleFixtureDiff: z.optional(z.string()).default(""),
  isTranslation: z.boolean(),
});

export class CodeSnippetsShowController extends Control {
  constructor(el, options) {
    super(el, options);
    this.listItem = new CodeSnippetListItemController(this.element, {
      _app: this._app,
    });

    mountPromotedAdDisplay("#partner-display", {
      type: "image",
      id: "collection_show_ad",
      user: this._app.currentUser,
    });
    this._controllers.push(this.listItem);
  }

  ready() {
    // if an item is deep linked within the url, select it now
    const urlParams = locationParams();
    if (urlParams.sel) {
      const $target = $(`#${urlParams.sel}`).trigger("click");
      if ($target.length === 0) this.activateRoot();
    } else {
      // otherwise just activate the root item
      this.activateRoot();
    }
  }

  activateRoot() {
    $("#snippet_list .title-container:first").trigger("click");
  }

  renderMainView(props) {
    if (this.view) {
      Object.assign(this.view, props);
    } else {
      this.view = mountApp("#main-view", {
        highlight: highlightEscapedCode,
        renderMarkdown(markdown) {
          return markdownWithLanguage(markdown, this.language);
        },
        ...props,
      });
    }
  }

  // TODO Define schema for `CommentsProps`
  async renderComments(props) {
    props.votes =
      (await getUserVotes(props.userVotesUrl)) || this._app.data.votes || {};
    if (this.comments) {
      Object.assign(this.comments, {
        ...props,
      });
    } else {
      this.comments = mountComments(".comments-list-component", {
        _app: this._app,
        currentUser: this._app.currentUser,
        appData: this._app.data,
        getRoute: this._app.route.bind(this._app),
        alerts: this._app.alerts,
        ...props,
      });
    }
  }
}

CodeSnippetsShowController.prototype.events = {
  /** @this {CodeSnippetsShowController} */
  "#branches a.js-new-branch:not(.is-disabled) click"($el, event) {
    const href = $("article a.js-new-branch").attr("href");
    if (event.ctrlKey) {
      window.open(href);
    } else {
      Turbolinks.visit(href);
    }
  },
  /** @this {CodeSnippetsShowController} */
  "#branches a.js-new-branch:not(.is-disabled) auxclick"($el) {
    window.open($("article a.js-new-branch").attr("href"));
  },

  /** @this {CodeSnippetsShowController} */
  "a.js-approve:not(.is-disabled) click"($el, e) {
    e.preventDefault();

    this._app.confirmModal.show({
      titleHtml: "Are you sure you want to approve this translation?",
      messageHtml:
        "This cannot be undone, you will be merging this version directly into the code challenge. Are you sure?",
      cancelHtml: "Nevermind",
      confirmHtml: "I'm sure",
      confirm: () => {
        axios
          .post($el.attr("href"), { event: "approve" })
          .then((response) => {
            const json = response.data;
            if (json.success) {
              this._app.alerts.success(
                "Successfully enqueued a job to merge approved translation. This can take a few minutes to complete."
              );
              setTimeout(() => Turbolinks.visit(), 500);
            } else {
              this._app.alerts.fail(json.message || json.reason);
            }
          })
          .catch((error) => {
            handleCloseTranslationError.call(this, error, "approve");
          });
      },
    });
  },

  /** @this {CodeSnippetsShowController} */
  "a.js-reject:not(.is-disabled) click"($el, e) {
    e.preventDefault();

    this._app.confirmModal.show({
      titleHtml: "Are you sure you want to reject this translation?",
      messageHtml:
        "This cannot be easily undone, you will be marking this translation as rejected and removing it from this list. Are you sure?",
      cancelHtml: "Nevermind",
      confirmHtml: "I'm sure",
      confirm: () => {
        axios
          .post($el.attr("href"), { event: "reject" })
          .then((response) => {
            const json = response.data;
            if (json.success) {
              this._app.alerts.success("Successfully rejected a translation.");
              setTimeout(() => Turbolinks.visit(), 500);
            } else {
              this._app.alerts.fail(json.message || json.reason);
            }
          })
          .catch((error) => {
            handleCloseTranslationError.call(this, error, "reject");
          });
      },
    });
  },

  /** @this {CodeSnippetsShowController} */
  ".tree-container .title-container:not(.is-active) click"($el) {
    const params = locationParams();
    params.sel = $el.attr("id");
    replaceHistoryState(`${location.pathname}?${param(params)}`);

    $(".tree-container .title-container.is-active").removeClass("is-active");
    $el.addClass("is-active");
    const viewDataPath = $el.data("view-data-path");
    if (viewDataPath) {
      axios.get(viewDataPath).then((response) => {
        this.viewData = response.data;
        const isTranslation = this._app.data.isTranslation ?? false;
        this.renderMainView({
          ...DetailsProps.parse({
            ...this.viewData,
            isTranslation,
          }),
          ...TabsProps.parse({
            canClose: false,
            activeTab: "details",
            hasDiff:
              "codeDiff fixtureDiff descriptionDiff setupCodeDiff exampleFixtureDiff packageDiff"
                .split(" ")
                .some((k) => !!this.viewData[k]),
            authorView:
              this.viewData.user.username === this._app.currentUser.username,
            commentsCount: this.viewData.discourse?.comments?.length ?? 0,
            isPublished: this.viewData.publishedAt != null,
            ...this.viewData,
          }),
          ...DiffsProps.parse({
            isTranslation,
            ...this.viewData,
          }),
        });

        this.renderComments(this.viewData.discourse);

        $(".js-new-branch").toggleClass(
          "is-disabled",
          this.viewData.state === "draft"
        );
      });
    }
  },
};

function handleCloseTranslationError(error, action) {
  if (error.response) {
    const json = error.response.data;
    if (error.response.status === 401) {
      this._app.alerts.fail(
        json.reason.replace(/^User .+:/, `Failed to ${action}:`)
      );
    } else {
      this._app.alerts.fail(json.reason || json.message);
    }
  } else if (error.request) {
    this._app.alerts.fail("No response was received. Please try again.");
  } else {
    this._app.alerts.fail(error.message || "Unknown Error");
  }
}
